import { render, staticRenderFns } from "./ViewCategoryPage.vue?vue&type=template&id=cae9fdc8"
import script from "./ViewCategoryPage.vue?vue&type=script&lang=js"
export * from "./ViewCategoryPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports