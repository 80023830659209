<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${category.name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ category.name }}
          </dd>
        </dl>

        <dl class="row" v-if="category.parent">
          <dt class="col-sm-4">{{ $t("CATEGORIES.PARENT_CATEGORY") }}</dt>
          <dd class="col-sm-8">
            <category :category="category.parent" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("CATEGORIES.COLOR") }}</dt>
          <dd class="col-sm-8">
            <div
              class=""
              :style="`height: 45px!important; width: 45px!important; border-radius: 8px; background-color:${category.color};`"
            ></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="category.excerpt"></div>
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="category.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(category.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(category.updated_at) }}
          </dd>
        </dl>
        <div class="row">
          <div class="col-3">
            <img
              v-if="category.icon"
              :src="`${category.icon}`"
              class="argon-image mb-2"
              style="width: 100%"
            />
            <img v-else src="/img/placeholder.jpg" class="argon-image mb-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from "@/components/Category.vue";

export default {
  name: "category-view-global",

  props: ["category"],

  components: { Category },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    categoryUpdated() {
      this.$emit("categoryUpdated", true);
    },
  },

  mounted() {},

  watch: {
    category(category) {},
  },
};
</script>
